import React, { Fragment, useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import axios from "axios";
import { Card, Carousel, Col, Container, Row, Placeholder } from "react-bootstrap";
import TimerCountDown from "./TimerCountDown"
import { Button, Divider, Icon, Label } from "semantic-ui-react";
import { useMyGlobal } from "./GlobalData";
import { useNavigate } from "react-router-dom";
import { IoFlash } from "react-icons/io5";   

const Flashsale = ({ dataFs }) => {
    const navigate = useNavigate()
    const [productsFs, setProductsFs] = useState([])
    const idCustomer = (localStorage.getItem('loginDataRr')) ? JSON.parse(localStorage.getItem('loginDataRr')).id : null
    const [currentTime, setCurrentTime] = useState(1)
    const { token, wishData, windowWidth } = useMyGlobal()
    const [placeholder, setPlaceholder] = useState(true)
    const groupedData = []
    const itemsPerCarousel = (() => {
        if (windowWidth <= 700) {
            return 2
        } else if (windowWidth <= 1200 && windowWidth > 700 ){
            return 4
        } else {
            return 6
        }
    })()
    
    for (let i = 0; i < productsFs.length; i += itemsPerCarousel) {
        groupedData.push(productsFs.slice(i, i + itemsPerCarousel));
    }

    const getFSProd = async (id_fs) => {
        const param = {
            id_fs: id_fs,
            id_customer: idCustomer
        }

        await axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner/controler/event.php/?index=1`,
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': token
            },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) { console.log(res.data.data)
                    if (res.data.data.length > 0 && currentTime > 0) {
                        setProductsFs(res.data.data)
                    }
                }
            })
    }

    const handleClickViewAll = (product) => {
        navigate('/product/flashsaleProductPage', { state: product })
    }

    useEffect(() => {
        getFSProd(dataFs[0].id_fs)
    }, [])

    useEffect(() => {
        if (productsFs.length > 0) {
            setPlaceholder(false)
        }
    }, [productsFs])

    return (
        <>
            {dataFs.length > 0 &&
                dataFs.map((fsItem, idx) => (
                    <Container fluid className="mt-4 mb-4" key={idx}>
                        <React.Fragment>
                            <Row>
                                <Col md={12} className="text-start">
                                    <div className="text-center mb-3"><IoFlash className="fs-2"/></div>
                                    <Divider horizontal className="mb-4">{fsItem.name}</Divider>
                                    {/* <Label content={(fsItem.name)} icon="tags" className="fs-5 mb-5" basic color="black" /> */}
                                    <div><span className="me-3">Will end on</span>
                                        <span className="fw-bold bg-orange">
                                            <Label icon="time" color="orange" content={<TimerCountDown expiryTime={(fsItem.date_end)} setCurentTime={setCurrentTime} sendTime={true} />}/>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            {/* <Row >
                                <Col>
                                    <Row className="mt-2 justify-content-start">
                                        {
                                            (productsFs.length > 0) ? ( 
                                                productsFs.map((item, i) => {
                                                    const isWish = wishData.some(wishItem => wishItem.id_product === item.id_product);
                                                    return (
                                                        <ProductCard data={item} key={i} option="flashsale" isWish={isWish}/>
                                                    )
                                                })
                                            )
                                                :
                                                <>
                                                    <Col className="text-start mt-3" xxl={2} xl={2} lg={3} md={3} sm={4} xs={6}>
                                                        <Placeholder style={{ width: '100%' }} key={idx}>
                                                            <Placeholder.Header>
                                                                <Placeholder.Image square />
                                                            </Placeholder.Header>
                                                            <Placeholder.Paragraph>
                                                                <Placeholder.Line />
                                                                <Placeholder.Line length="short" />
                                                                <Placeholder.Line length="very short" />
                                                                <Placeholder.Line length="short" />
                                                                <Placeholder.Line length="long" />
                                                            </Placeholder.Paragraph>
                                                        </Placeholder>
                                                    </Col>
                                                </>
                                        }
                                    </Row>
                                </Col>
                            </Row> */}
                            <Row>
                                <Carousel keyboard={true} variant="dark" indicators={true} controls={false} touch={true}>
                                    {
                                        !placeholder ?
                                            groupedData.map((group, g) => {
                                                return (
                                                    <Carousel.Item key={g} style={{height:'30em'}}>
                                                        <Row>
                                                            {group.map((item, i) => (
                                                                <Col xl={2} lg={3} md={3} sm={6} xs={6} key={i}>
                                                                    <ProductCard
                                                                        data={item}
                                                                        option="flashsale"
                                                                        isWish={wishData.some(wishItem => wishItem.id_product === item.id_product)}
                                                                    />
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </Carousel.Item>
                                                )
                                            })
                                            :
                                            <Carousel.Item>
                                                <Row className="mt-3">{
                                                    Array.from({ length: 6 }, (_, index) => (
                                                        <Col lg={2} md={3} sm={6} xs={6} key={index} className="mb-4">
                                                            <Card className="text-start">
                                                                <Card.Img variant="top" src="https://via.placeholder.com/500x400.png" />
                                                                <Card.Body>
                                                                    <Placeholder as={Card.Title} animation="glow" className="mb-4"      >
                                                                        <Placeholder xs={12} />
                                                                    </Placeholder>
                                                                    <Placeholder as={Card.Text} animation="glow">
                                                                        <Placeholder xs={6} className="mb-3" />
                                                                        <Placeholder xs={8} className="mb-3" />
                                                                        <Placeholder xs={9} />  <Placeholder xs={2} />
                                                                    </Placeholder>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    ))
                                                }</Row>
                                            </Carousel.Item>
                                    }
                                </Carousel>
                            </Row>
                            <Row className="text-end">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Button content="View all product" icon="list alternate outline" basic color="orange" size="small" onClick={() => handleClickViewAll(productsFs)} />
                                </Col>
                            </Row>
                        </React.Fragment>
                    </Container>
                ))
            }
        </>
    )
}

export default Flashsale