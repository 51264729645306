
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderClosed, faHeart, faHeartCircleCheck, faL } from '@fortawesome/free-solid-svg-icons';
import { Card, Col, Row, ProgressBar, NavItem, Stack } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Label, Button, Popup, Icon } from 'semantic-ui-react';
import axios from 'axios';
import { useMyGlobal } from './GlobalData';
import { formatNumberWithCommas } from './utility';
import { Toast } from './Toast';


const ProductCard = ({ data, option, updateList, isWish }) => {
    const navigate = useNavigate()
    const [defaultImage, setDefaultImage] = useState('')
    const [productName, setProductName] = useState('noname')
    const [productNett, setProductNett] = useState()
    const [discountPercent, setDiscountPercent] = useState()
    const [discountValue, setDiscountValue] = useState()
    const [categoryName, setCategoryName] = useState()
    // const [isWish, setIsWish] = useState(false)
    const [stockInPercent, setStockInPercent] = useState(0)
    const [segment, setSegment] = useState('')
    const [delShow, setDelShow] = useState('none')
    const [price, setPrice] = useState(0)
    // const [fsData, setFsData] = useState([])
    const idCustomer = (localStorage.getItem('loginDataRr')) ? JSON.parse(localStorage.getItem('loginDataRr')).id : null
    const [wish, setWish] = useState(null)
    const { token, fsData, windowWidth, isPortrait, included } = useMyGlobal()


    const stickyRibonFls = {
        position: 'sticky',
        display: 'flex',
        alignItems: 'center',
        lineHeight: 14,
        fontWeight: 600,
        fontSize: '0.714286rem',
        color: 'rgb(255, 255, 255)',
        height: '1.5rem',
        padding: '2px 20px 2px 8px',
        margin: '-20px 0px 0px',
        // backgroundImage: 'url("https://images.tokopedia.net/img/phOWBv/2023/6/28/94f6876a-7258-4aa7-8bb4-d015a21c3258.png")',
        background: 'linear-gradient(to left, #FFA500, #cc3b21)',
        backgroundPosition: 'right bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: '0px 20px 0px 0px',
    }

    const discountTag = {
        position: 'absolute',
        display: 'flex',
        top: '0px',
        alignItems: 'center',
        lineHeight: 14,
        background: 'linear-gradient(to right, #cc3b21, #FFA500)',
        padding: windowWidth > 600 ? '2px 20px 2px 8px' : '2px 10px 2px 8px',
        margin: '10px 0px 0px',
        height: windowWidth > 600 ? '1.5rem' : '1.1rem',
        fontSize: '0.714286rem',
        color: 'rgb(255, 255, 255)',
        fontWeight: 600,
        fontSize: '0.714286rem',
        borderRadius: '0 0 5px 0px',
    }

    const soldTag = {
        alignItems: 'center',
        background: 'linear-gradient(to right, #cc3b21, #FFA500)',
        fontSize: '0.714286rem',
        color: 'rgb(255, 255, 255)',
        fontWeight: 600,
        fontSize: '0.714286rem',
        borderRadius: '0 0 5px 0px',
    }

    const percentTag = {
        alignItems: 'center',
        background: 'linear-gradient(to right, #cc3b21, #FFA500)',
        fontSize: '0.714286rem',
        color: 'rgb(255, 255, 255)',
        fontWeight: 600,
        fontSize: '0.714286rem',
        borderRadius: '0 0 5px 0px',
    }

    const percentTagNormal = {
        alignItems: 'center',
        background: 'linear-gradient(to right, grey, lightgrey)',
        fontSize: '0.714286rem',
        color: 'rgb(255, 255, 255)',
        fontWeight: 600,
        fontSize: '0.714286rem',
        borderRadius: '0 0 5px 0px',
    }

    // const getFlashsale = async (idFs) => {
    //     const param = {
    //         id_fs: idFs
    //     }
    //     try {
    //         const res = await axios.post(`${global.SRV}api_rudrunner/controler/event.php/?index=4`, param, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'authorization': token // Ensure token is defined or passed to the component
    //             }
    //         });

    //         if (res.data.state === 200) {
    //             setFsData(res.data.data);
    //         }
    //     } catch (error) {
    //         // Handle errors, e.g., log the error or show a notification
    //         console.error('Error fetching flashsale data:', error);
    //     } finally {
    //         // Set isFlashsaleCalled to true after the call is completed, whether it succeeds or fails
    //         setIsFlashsaleCalled(true);
    //     }
    // }

    const setProductAtribute = (products) => {
        if (products) {
            setDefaultImage(products.image.filter(i => i.is_default == 1).map(i => i.image))
            setProductName(products.product_name)
            setProductNett(products.final_price)
            setDiscountPercent((fsData.length > 0) ? products.fs_active == 'Y' ? products.fs_discount : products.discount.discount_value : products.discount.discount_value)
            setDiscountValue((fsData.length > 0) ? (products.price * products.fs_discount) / 100 : products.discount.discount_total)
            setCategoryName(Array.isArray(products.category_name) ? products.category_name.length > 1 ? products.category_name.map(o => o + ', ') : products.category_name[0] : products.category_name)
            // setIsWish(products.is_wishlist)
            setStockInPercent((parseFloat(products.qty) / parseFloat(products.initial_qty)) * 100)
            setSegment(products.segment_name)
            setPrice(products.price)
        }
    }

    const handleClick = (data) => {
        if (fsData.length > 0) data.flashsale = fsData
        navigate(`/product/${data.id_product}`, { state: data })
    }

    // const getIsWish = async (id_customer, id_product) => {
    //     const param = new URLSearchParams()
    //     param.append('id_customer', id_customer)
    //     param.append('id_product', id_product)

    //     await axios({
    //         method: "POST",
    //         url: "https://hoopsdevteam.com/api_web/controler/product.php?index=7", // OVER REQUEST
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //             'authorization': token
    //         },
    //         data: param
    //     })
    //         .then(response => {
    //             if (response.data.state === 200) {
    //                 (response.data.exist === 1) ? setWish(true) : setWish(false)
    //             }
    //         })
    // }

    const handleClickWishlist = (id_customer, id_product, id_category, e) => {
        e.stopPropagation()
        const param = new URLSearchParams()
        param.append('id_customer', id_customer)
        param.append('id_product', id_product)
        param.append('id_category', id_category)

        axios({
            method: "POST",
            url: "https://hoopsdevteam.com/api_web/controler/product_rr.php?index=6",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        }).
            then(response => {
                if (response.data.state === 200) {
                    setWish(true)
                    Toast.fire({
                        icon:"success",
                        text : "Successfully added to wishlist",
                        position: "top-end",
                    })
                }
            })
    }


    const handleDeleteWish = (idProduct, idCust, event) => {
        event.stopPropagation()
        const param = new URLSearchParams()
        param.append('id_product', idProduct)
        param.append('id_customer', idCust)

        axios({
            method: "POST",
            url: 'https://hoopsdevteam.com/api_web/controler/product_rr.php?index=8',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: param
        })
            .then(response => {
                // updateList(idProduct)
                setWish(false)
            })
    }

    const stockInfo = (stockPercents) => {
        let stockPercent = Math.round(stockPercents)

        if (stockPercent <= 100 && stockPercent > 0) {
            return "Enough stock"
        } else if (stockPercent == 0) {
            return "Unavailable"
        } else if (stockPercent <= 20) {
            return "Run out soon"
        } else {
            return "Unknown" + stockPercents
        }
    }

    const RenderPriceCoret = ({ price }) => {
        if (option === "flashsale" || option === "fsPage") {
            return (<s>{global.IDR.format(price)}</s>)
        } else {
            if (data.isDiscounted == 1 && discountPercent) {
                return (<s>{global.IDR.format(price)}</s>)
            } else if (data.fs_on > 0) {
                return (<s>{global.IDR.format(price)}</s>)
            } else if (data.isDiscounted == 1 && discountPercent == 0) {
                return ''
            } else {
                return ''
            }
        }
    }

    const handleDscPer = () => {
        if (data.fs_on && data.isDiscounted === '1') {
            return data.fs_discount
        } else if (data.fs_on && data.isDiscounted === '0') {
            return data.fs_discount
        } else if (!data.fs_on && data.isDiscounted === '1') {
            if (parseInt(data.isDiscounted) === 1 && discountPercent) {
                return discountPercent
            } else if (data.isDiscounted === '1' && discountPercent == 0) {
                return 0
            } else if (parseInt(data.isDiscounted) === 0) {
                return 0
            }
        } else if (!data.fs_on) {
            if (parseInt(data.isDiscounted) === 1 && discountPercent) {
                return discountPercent
            } else if (parseInt(data.isDiscounted) === 0) {
                return 0
            }
        } else {
            return 0
        }
    }

    const handleDscVal = () => {
        if (data.fs_on) {
            return <>{data.fs_discount_value}</>
        } else if (!data.fs_on) {
            if (parseInt(data.isDiscounted) === 1 && discountValue) {
                return <>{discountValue}</>
            } else if (parseInt(data.isDiscounted) === 0) {
                return '0'
            }
        }
    }

    const DiscountLabel = ({ isDiscount }) => {
        if (isDiscount === '1') {
            return (
                <>On Sale</>
            )
        }

        return false
    }

    const RibonDsc = ({ fsData, opt }) => {
        const labelFs = fsData[0]?.name
        if (typeof opt !== 'undefined' && (opt === 'flashsale' || opt === 'fsPage')) { 
            return (<div className='w-100 text-start ps-3' style={stickyRibonFls}>{ fsData ? labelFs : '...'}</div>)
        } else if (data.isDiscounted === '1' && !data.fs_on && data.discount.discount_value > 0) { 
            return (<div style={stickyRibonFls}><DiscountLabel isDiscount={data.isDiscounted} /></div>)
        } else if (data.discount.discount_value == 0 && !data.fs_on) {
            return <></>
        } else {
            if (fsData.length > 0 && data.fs_on) { 
                return (<div className='text-start ps-3' style={stickyRibonFls}>{fsData.length > 0 ? fsData[0].name : '...'}</div>)
            }
        }


        // if (data.isDiscounted === '1' && !data.fs_on){ 
        //     return (<div style={stickyRibonFls}><DiscountLabel isDiscount={data.isDiscounted}/></div>)
        // }

    }

    const RibonNewProd = ({ isNew }) => {
        if (isNew) {
            return (<div className='text-start ps-3' style={discountTag}>New</div>)
        }
    }


    const DiscountAmount = () => {
        if (data.fs_on && data.isDiscounted === '0') {
            return (
                <>{'Dsc. ' + formatNumberWithCommas(data.fs_discount_value)}</>
            )
        } else if (data.fs_on && data.isDiscounted === '1') {
            return (
                <>{'Dsc. ' + formatNumberWithCommas(data.fs_discount_value)}</>
            )
        } else if (!data.fs_on && data.isDiscounted === '1') {
            return (
                <>{'Dsc. ' + formatNumberWithCommas(data.discount.discount_total)}</>
            )
        } else if (!data.fs_on && data.isDiscounted == '0') {
            return (<>{'Dsc. ' + formatNumberWithCommas(0)}</>)
        } else {
            return (<>{'Dsc. ' + formatNumberWithCommas(0)}</>)
        }
    }

    useEffect(() => {
        let voucherTemp = []
        included.forEach(i => {
            if (data.id_product == i.id_product) {
                voucherTemp.push(i)
            }
        })
        data.included_on_voucher = voucherTemp
        setProductAtribute(data)
        // data.id_product == '555088-202' ? console.log(data) : <></>
    }, [data])
 
    console.log(data.included_on_voucher)
    return (
        <Col xxl={option === 'fsPage' || option === 'newProduct' ? 2 : option === 'wishlist' ? 3 : option === "flashsale" ||  option === "dscProduct" ? 12 : 3}
            xl={option === 'fsPage' || option === 'newProduct' ? 2 : option === 'wishlist' ? 3 : option === "flashsale" || option === "dscProduct" ? 12 : 3}
            lg={option === 'fsPage' || option === 'newProduct' ? 3 : option === 'wishlist' ? 4 : option === "flashsale" || option === "dscProduct" ? 12 : 3}
            md={option === 'fsPage' || option === 'newProduct' ? 4 : option === 'wishlist' ? 6 : option === "flashsale" || option === "dscProduct" ? 12 : 4}
            sm={option === 'fsPage' || option === 'newProduct' ? 6 : option === 'wishlist' ? 6 : option === "flashsale" || option === "dscProduct" ? 12 : 6}
            xs={option === 'fsPage' || option === 'newProduct' ? 6 : option === 'wishlist' ? 6 : option === "flashsale" || option === "dscProduct" ? 12 : (!isPortrait && windowWidth > 1000) ? 12 : 6}
            key={data.id} className="mt-3 mb-3">
            <Card
                onClick={() => handleClick(data)}
                className="product-card"
                onMouseEnter={() => setDelShow('block')}
                onMouseLeave={() => setDelShow('none')}
            >
                {
                    (option === "wishlist") &&
                    <Button
                        content="Delete"
                        style={{ position: 'absolute', top: '2%', right: '3%', display: delShow }}
                        icon='trash'
                        size='small'
                        onClick={(e) => handleDeleteWish(data.id_product, idCustomer, e)}
                    />
                }
                <div>
                    <Card.Img variant="top" className='img-fluid' src={defaultImage.length > 0 ? defaultImage : 'https://via.placeholder.com/500x400.png'} />
                    <RibonDsc fsData={fsData} opt={option} />
                    <RibonNewProd isNew={data.is_new} />
                </div>

                <Card.Body className="text-start">
                    <Card.Text>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <div className="text-truncate fw-bold" data-bs-toggle="tooltip" data-bs-placement="top" title={productName}>
                                    {productName}
                                </div>
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="fw-bold" style={{fontSize:'1.1em'}}>{global.IDR.format(productNett)}</div>
                            </Col>
                            <Col  xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div><RenderPriceCoret price={price} /></div>
                            </Col>

                        </Row>
                        <Row className='mb-2'>
                            <Col lg={6} md={12} sm={12} xs={12} >
                                {
                                    (option === "flashsale" || option === "fsPage") ?
                                        <>
                                            {handleDscPer() > 0 ?
                                                <span><Label size="small" style={percentTag}>{(data.fs_discount) ? data.fs_discount : 0}%</Label></span>
                                                :
                                                <Popup
                                                    content="Normal Price"
                                                    size='mini' 
                                                    trigger={
                                                    <span><Label size='small' style={percentTagNormal}>No Dscn.</Label></span>
                                                }/>
                                            }
                                            {/* <span className="ms-2" style={{fontSize:'0.9em'}}><DiscountAmount /></span> */}
                                        </>
                                        :
                                        <>
                                            {handleDscPer() > 0 ?
                                                <span><Label size="small" style={percentTag}>{handleDscPer()}%</Label></span>
                                                :
                                                <Popup
                                                    content="Normal Price"
                                                    size='mini' 
                                                    trigger={
                                                    <span><Label size='small' style={percentTagNormal}>No Dscn.</Label></span>
                                                }/>
                                            }
                                            {/* <span className="ms-2" style={{fontSize:'0.9em'}}><DiscountAmount /></span> */}
                                        </>
                                }

                            </Col>
                            <Col className='text-end' lg={6} md={12} sm={12} xs={12}>
                                {
                                    data.qty <= 0 ?
                                        <Label size='small' style={soldTag}>Soldout</Label>
                                        :
                                        <></>
                                }

                            </Col>
                        </Row>
                        {
                            data.included_on_voucher && data.included_on_voucher.length > 0 ?
                                <Row>
                                    <Col>{
                                        data.included_on_voucher.map((item, i) => (
                                            item.type === 'D' ?
                                                <div style={{ fontSize: '0.9em', fontWeight: 'bold', color: '#F7634C' }}><Icon name='tag'/>{item.name} -{item.percent}% off</div>
                                            :
                                                <div style={{ fontSize: '0.9em', fontWeight: 'bold', color: '#F7634C' }}><Icon name='tag'/>{item.name} {global.IDR.format(item.nominal)}</div>
                                            ))
                                    }</Col>
                                </Row>
                                : 
                                <Row className='mb-4'>
                                    <Col></Col>
                                </Row>
                        }
                        <Row className="mt-2">
                            <Col className="text-start" md={8} sm={8} xs={8}>
                                {/* <Label size="small">+{data.point} Point</Label> */}
                                <div><small>#{data.id_product}</small></div>
                            </Col>
                            <Col className="text-end" md={4} sm={4} xs={4}>
                                {
                                    !isWish && !wish ?
                                        <Popup content="Add to wishlist" size='mini' trigger={
                                            <FontAwesomeIcon icon={faHeart} style={wish ? {color : 'black'} : { color: 'lightgray' }}
                                                onClick={(e) => handleClickWishlist(idCustomer, data.id_product, data.id_category, e)} />
                                        } />
                                        :
                                        <Popup content="Wishlisted" size='tiny' trigger={
                                            <FontAwesomeIcon icon={faHeart} style={{ color: '#FF5733' }}
                                                onClick={(e) => {
                                                    handleDeleteWish(data.id_product, idCustomer, e)
                                                    Toast.fire({
                                                        icon : "success",
                                                        text : "Removed from wishlist",
                                                        position : "top-end"
                                                    })
                                                }} />
                                        } />
                                }
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className='text-start text-truncate' md={7} sm={12} sx={12}>
                                <span><FontAwesomeIcon className='me-2' icon={faFolderClosed} /></span>
                                <Popup content={categoryName} trigger={<span className="" style={{ fontSize: '0.8em' }}>{categoryName}</span>} size='tiny' />
                            </Col>
                            <Col className={!isPortrait && windowWidth > 1000 ? 'text-end' : 'text-start mt-1'} md={5}>
                                <Label style={{ fontSize: '0.7em' }}>{segment}</Label>
                            </Col>
                        </Row>
                        {/* <Col>
                            <div className="mt-3">
                                <ProgressBar now={stockInPercent} variant="default" max={100} min={0} style={{ height: '3px', backgroundColor: '#ced4da' }} />
                                <small className="text-black-50">{stockInfo(stockInPercent)}</small>
                            </div>
                        </Col> */}
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col >
    )
}

export default ProductCard;